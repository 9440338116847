import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import getAvailableAppointmentsSlots from '../api/getAvailableAppointmentsSlots'
import { Clinic } from '../store/invitation'

const useClinicAppointmentSelector = (
  mode: string,
  clinicInformation: Clinic,
  email: string,
  language: string,
  dates: [string | null, string | null],
) => {
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const [appointments, setAppointments] = useState<{
    [key: string]: string[];
  }>({})
  const isNextButtonEnabled = !!selectedDate && !!selectedTime
  const availableTimes = selectedDate
    ? appointments[selectedDate as keyof typeof appointments]
    : null
  const availableDateTimes = Object.keys(appointments)

  const handleDateChange = (date: string) => {
    setSelectedDate(date)
    setSelectedTime(null)
  }

  const handleTimeChange = (time: string) => {
    setSelectedTime(time)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          language,
          service_center_ids: clinicInformation.id.toString(),
          email,
          mode,
        })
        if (dates[0]) {
          queryParams.append('start_date', dates[0])
        }
        if (dates[1]) {
          queryParams.append('end_date', dates[1])
        }

        const result = await getAvailableAppointmentsSlots(
          queryParams.toString(),
        )
        const processedData = processAvailableAppointmentSlots(
          result?.data[clinicInformation.id.toString()],
          dates[0],
          dates[1],
        )
        setAppointments(processedData)
        handleDateChange(Object.keys(processedData)[0])

        setLoading(false)
      } catch (err) {
        setAppointments({})
      }
    }
    setLoading(true)
    fetchData()
  }, [])

  return {
    clinicLocation: clinicInformation,
    selectedDate,
    selectedTime,
    handleDateChange,
    handleTimeChange,
    isNextButtonEnabled,
    availableDates: availableDateTimes,
    availableTimes,
    loading,
  }
}

export type AppointmentSelectorProps = ReturnType<typeof useClinicAppointmentSelector>

export default useClinicAppointmentSelector

function processAvailableAppointmentSlots(
  data: { [date: string]: string[] },
  startDate: string | null, // format: 'MM/DD/YYYY'
  endDate: string | null, // format: 'MM/DD/YYYY'
) {
  if (!startDate && !endDate) {
    return data
  }

  // sort the dates
  const availableDates = Object.keys(data).sort((a, b) => {
    const dateA = dayjs(a, 'MM/DD/YYYY')
    const dateB = dayjs(b, 'MM/DD/YYYY')
    if (dateA.isBefore(dateB)) {
      return -1
    }
    if (dateA.isAfter(dateB)) {
      return 1
    }
    return 0
  })

  const start = startDate ? dayjs(startDate, { format: 'MM/DD/YYYY' }) : dayjs()
  const end = endDate ? dayjs(endDate, { format: 'MM/DD/YYYY' }) : dayjs()
  const result: { [key: string]: string[] } = {}

  availableDates.forEach((date) => {
    const day = dayjs(date)
    if ((day.isAfter(start) || day.isSame(start)) && (day.isBefore(end) || day.isSame(end))) {
      if (data[date].length > 0) {
        result[date] = data[date].map((time) => `${time}:00`)
      }
    }
  })
  return result
}
